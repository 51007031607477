var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mx-2"},[_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"col",staticStyle:{"text-align":"left"}},[(_vm.allows_crud)?_c('button',{directives:[{name:"can",rawName:"v-can",value:('mesh.add_competencetype'),expression:"'mesh.add_competencetype'"}],staticClass:"btn btn-secondary btn-sm",on:{"click":function($event){return _vm.$bvModal.show(`new-competence-type-modal`)}}},[_vm._v(" Agregar "+_vm._s(_vm.$getVisibleNames( "mesh.competencetype", false, "Tipo De Competencia" ))+" ")]):_vm._e()])]),_c('h3',{staticClass:"mt-3"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames("mesh.competencetype", true, "Tipos de Competencias"))+" "),_c('AlternativeNameButton',{attrs:{"table_name":'mesh.competencetype',"allows_crud":_vm.allows_crud}})],1),(_vm.searchCompetenceType.length > 0)?_c('GenericBTable',{staticClass:"competence-type-table",attrs:{"items":_vm.competence_types,"pagination":_vm.competence_types.length,"fields":_vm.CompetenceTypesFields,"filter":_vm.input_search,"show_pagination":true,"search_filter":true,"columns_display":true,"allows_crud":_vm.allows_crud,"display_id":_vm.display_id,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([(_vm.allows_crud)?{key:"cell(actions)",fn:function(row){return [_c('button-edit',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
            `Editar ${_vm.$getVisibleNames(
              'mesh.competencetype',
              false,
              'Tipo De Competencia'
            )}`
          ),expression:"\n            `Editar ${$getVisibleNames(\n              'mesh.competencetype',\n              false,\n              'Tipo De Competencia'\n            )}`\n          ",modifiers:{"v-secondary":true,"noninteractive":true}}],on:{"click":function($event){return _vm.$bvModal.show(`edit-competence-type-modal-${row.item.id}`)}}}),_c('button-delete',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
            `Eliminar ${_vm.$getVisibleNames(
              'mesh.competencetype',
              false,
              'Tipo De Competencia'
            )}`
          ),expression:"\n            `Eliminar ${$getVisibleNames(\n              'mesh.competencetype',\n              false,\n              'Tipo De Competencia'\n            )}`\n          ",modifiers:{"v-secondary":true,"noninteractive":true}}],on:{"click":function($event){return _vm.askForDeleteCompetenceType(row.item.id)}}}),_c('b-modal',{attrs:{"id":`edit-competence-type-modal-${row.item.id}`,"title":`Editar ${_vm.$getVisibleNames(
            'mesh.competencetype',
            false,
            'Tipo De Competencia'
          )}`,"size":"md","hide-footer":""}},[_c('CompetenceTypeForm',{attrs:{"CompetenceType":row.item,"show_title":false},on:{"updated":_vm.slotUpdatedCompetenceType}})],1)]}}:null],null,true)}):_c('div',[_c('br'),_c('strong',[_vm._v("No se han encontrado "+_vm._s(_vm.$getVisibleNames( "mesh.competencetype", true, "Tipos De Competencias" ))+".")])])],1),_c('b-modal',{attrs:{"id":`new-competence-type-modal`,"hide-footer":true,"title":`Crear ${_vm.$getVisibleNames(
      'mesh.competencetype',
      false,
      'Tipo De Competencia'
    )}`,"size":"md"}},[_c('CompetenceTypeForm',{attrs:{"show_title":false},on:{"created":_vm.slotCreatedCompetenceType}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }